<template>
  <div class="seal-material index-container">
    <common-header
        :is-show-menu="true"
        :client-width="clientWidth"
        current-menu="product"
        @openMobileMenu="openMobileMenu"
    />
    <seal-material :client-width="clientWidth"/>
    <sealing-equipment :client-width="clientWidth"/>
    <common-footer :is-index="true" v-show="clientWidth>mobileWidth"/>
    <mobile-footer v-show="clientWidth<=mobileWidth"/>
    <slide-menu ref="slideMenu"/>
  </div>
</template>

<script>
import CommonHeader from '@components/CommonHeader.vue';
import CommonFooter from '@components/CommonFooter.vue';
import MobileFooter from '@components/MobileFooter.vue';
import SlideMenu from '@components/Menu.vue';
import SealMaterial from './components/SealMaterial.vue';
import SealingEquipment from './components/SealingEquipment.vue';

export default {
  name: 'ProductIndex',
  components: {
    MobileFooter,
    CommonFooter,
    CommonHeader,
    SlideMenu,
    SealMaterial,
    SealingEquipment,
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  data() {
    return {
      clientWidth: 1920,
    };
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.clientWidth = document.body.clientWidth;
    },
    openMobileMenu() {
      this.$refs.slideMenu.onOpen();
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../less/mobileIndex.less";
</style>
