<template>
  <div class="seal-material-wrapper">
    <div :class="['seal-material',{'mobile-content':clientWidth<=mobileWidth}]">
      <div :class="['title']">印章材料</div>
      <div class="img-content" v-if="clientWidth>mobileWidth" @click="toShowDialog">
        <div class="line">
          <div class="item" v-for="(item,index) in lineOne" :key="index">
            <img :src="require(`../../../assets/imgs/sealMaterial/cpzz${3+index}@2x.png`)">
            <p>{{ item }}</p>
          </div>
        </div>
        <div class="line">
          <div class="item" v-for="(item,index) in lineTwo" :key="index">
            <p>{{ item }}</p>
            <img :src="require(`../../../assets/imgs/sealMaterial/cpzz${8+index}@2x.png`)">
          </div>
        </div>
        <div class="line">
          <div class="item" v-for="(item,index) in lineThree" :key="index">
            <img :src="require(`../../../assets/imgs/sealMaterial/cpzz${13+index}@2x.png`)">
            <p>{{ item }}</p>
          </div>
        </div>
      </div>
      <div class="img-content" v-else @click="toShowDialog">
        <div class="line">
          <div class="item mobile-item" v-for="(item,index) in mobileLineOne" :key="index">
            <img :src="require(`../../../assets/imgs/sealMaterial/cpzz${3+index}@2x.png`)">
            <p>{{ item }}</p>
          </div>
        </div>
        <div class="line">
          <div class="item mobile-item" v-for="(item,index) in mobileLineTwo" :key="index">
            <p>{{ item }}</p>
            <img :src="require(`../../../assets/imgs/sealMaterial/cpzz${6+index}@2x.png`)">
          </div>
        </div>
        <div class="line">
          <div class="item mobile-item" v-for="(item,index) in mobileLineThree" :key="index">
            <img :src="require(`../../../assets/imgs/sealMaterial/cpzz${9+index}@2x.png`)">
            <p>{{ item }}</p>
          </div>
        </div>
        <div class="line">
          <div class="item mobile-item" v-for="(item,index) in mobileLineFour" :key="index">
            <p>{{ item }}</p>
            <img :src="require(`../../../assets/imgs/sealMaterial/cpzz${12+index}@2x.png`)">
          </div>
        </div>
        <div class="line">
          <div class="item mobile-item" v-for="(item,index) in mobileLineFive" :key="index">
            <img :src="require(`../../../assets/imgs/sealMaterial/cpzz${15+index}@2x.png`)">
            <p>{{ item }}</p>
          </div>
        </div>
      </div>
    </div>

    <apply-dialog ref="applyDialog" :clientWidth="clientWidth"/>
  </div>

</template>

<script>
import ApplyDialog from '@components/ApplyDialog.vue';

export default {
  name: 'SealMaterial',
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  components: {
    ApplyDialog,
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  data() {
    return {
      lineOne: ['日产鸿翔印台-1', '日产鸿翔印台-2', '日产鸿翔印台-3', '日产paper印台-1', '日产paper印台-2'],
      lineTwo: ['日产paper印台-3', '日产鸿翔印油红色', '日产鸿翔印油蓝色', '日产印油-光敏油（红）', '日产印油-光敏油（蓝）'],
      lineThree: ['日产印油-硬质印油', '日产印油-万能油', '新型树脂板', '橡胶', '脱模剂'],
      mobileLineOne: ['日产鸿翔印台-1', '日产鸿翔印台-2', '日产鸿翔印台-3'],
      mobileLineTwo: ['日产paper印台-1', '日产paper印台-2', '日产paper印台-3'],
      mobileLineThree: ['日产鸿翔印油红色', '日产鸿翔印油蓝色', '日产印油-光敏油（红）'],
      mobileLineFour: ['日产印油-光敏油（蓝）', '日产印油-硬质印油', '日产印油-万能油'],
      mobileLineFive: ['新型树脂板', '橡胶', '脱模剂'],
    };
  },
  methods: {
    toShowDialog() {
      this.$refs.applyDialog.openDialog();
    },
  },
};
</script>

<style lang="less" scoped>
.seal-material {
  margin: 130px 0 254px;

  .title {
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 36px;
    position: relative;
    text-align: center;
    margin-bottom: 95px;

    &::after {
      position: absolute;
      content: '';
      width: 120px;
      height: 8px;
      background: #F75959;
      border-radius: 4px;
      bottom: -24px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .line {
    display: flex;
    justify-content: center;

    .item {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 115px;
        height: 174px;
      }

      p {
        width: 115px;
        margin: 0;
        padding: 0 10px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
      }
    }

    .mobile-item {
      img {
        width: 53px;
        height: 80px;
      }
    }
  }
}

.mobile-content {
  margin: 110px auto 130px;

  .title {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 36px;

    &::after {
      width: 60px;
      bottom: -5px;
      height: 4px;
    }
  }

  .line {
    .item {
      p {
        width: 55px;
        padding: 0;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
      }
    }
  }
}
</style>
